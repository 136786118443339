import React from "react"
import PropTypes from "prop-types"
import Alert from "./Alerts"

class PlantLists extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            existing: this.props.existing,
            count: this.props.count
        }

    }

    render () {
        var ui
        if (this.props.current_user) {
            if (this.state.existing) {
                ui = <button className="btn-tertiary -has-icon" onClick={evt => this.remove(evt)}><svg className="icon icon-danger"><use xlinkHref="/icons/__remixicon.sprite.svg#heart-add-fill"/></svg><span className="profile-action-count">{this.state.count}</span></button>
            } else {
                ui = <button className="btn-tertiary -has-icon" onClick={evt => this.add(evt)}><svg className="icon icon-primary"><use xlinkHref="/icons/__remixicon.sprite.svg#heart-add-line"/></svg><span className="profile-action-count">{this.state.count}</span></button>
            }
        } else {
            ui = <span><svg className="icon icon-gray"><use xlinkHref="/icons/__remixicon.sprite.svg#heart-add-line"/></svg></span>
        }
        return ui;
    }

    add (evt) {
        evt.preventDefault();

        fetch(this.props.list_slug + "/add", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': this.props.token
            },
            body: JSON.stringify({list: {plant_id: this.props.plant_id}})
        })
        .then(response => {
            if (response.ok) {
                this.setState({existing: true, count: this.state.count+1})
                Alert.show("✅ Added to favourites")
            } else {
                throw {name: "AddToListError", response: response}
            }
        })
    }

    remove (evt) {
        evt.target.setAttribute("disabled", "disabled");
        evt.preventDefault();

        fetch(this.props.list_slug + "/remove", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': this.props.token
            },
            body: JSON.stringify({list: {plant_id: this.props.plant_id}})
        })
        .then(response => {
            if (response.ok) {
                this.setState({existing: false, count: this.state.count-1})
                Alert.show("❎ Removed from favourites")
            } else {
                throw {name: "RemoveFromListError", response: response}
            }
        })
    }
}

export default PlantLists
