import React from "react"
import dispatcher from "packs/dispatcher"

class ListSelectBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: false
        }
    }

    render () {
        return (
            <input
              type="checkbox"
              className="input-checkbox mar-r-sm"
              onChange={event => {this.toggle(event)}}
              defaultChecked={this.state.selected}
            />
        );
    }

    toggle (event) {
        if (this.state.selected) {
            this.setState({selected: false})
        } else {
            this.setState({selected: true})
        }
        dispatcher.dispatch({eventName: "TEST"})
    }
}

export default ListSelectBox
