import React from "react"

class ToggleToggle extends React.Component {
    constructor(props) {
        super(props);

        this.state = {toggled: false}

        //this we need so we can use this.state in handleClick
        this.handleClick = this.handleClick.bind(this);

        this.icon = "/icons/__remixicon.sprite.svg#" + this.props.icon;
        this.iconSize = this.props.iconSize || "md";

        this.text = this.props.text;

        this.focus = this.props.focus;
    }

    componentDidMount() {

        this.target = document.querySelectorAll(this.props.target)[0];

    }


    handleClick(event) {

        if (this.props.toggleClass) {
            this.target.classList.toggle(this.props.toggleClass)
        } else {
            this.target.classList.toggle('-is-toggled')
        }

        // Set the toggled icon
        if (this.props.iconToggled) {
            if (!this.state.toggled) {
                this.icon = "/icons/__remixicon.sprite.svg#" + this.props.iconToggled;
            } else {
                this.icon = "/icons/__remixicon.sprite.svg#" + this.props.icon;
            }
        }

        // Set the toggled text
        if (this.props.textToggled) {
            if (!this.state.toggled) {
                this.text = this.props.textToggled;
            } else {
                this.text = this.props.text;
            }
        }


        // Focus!
        if (this.props.focus) {

            let x = document.getElementById(this.focus);

            setTimeout(() => {
                x.focus();
            }, 50);

        }

        this.setState({toggled: !this.state.toggled});

    }



    render () {

        var toggle = "";
        var iconClass = "icon";

        if (this.props.iconSize == "md") {
            iconClass = "icon icon-sm"
        } else if (this.props.iconSize == "lg") {
            iconClass = "icon icon-lg"
        }


        if (this.props.icon && !this.props.text) {
            toggle = <div onClick={evt => this.handleClick(evt)} className={this.props.class}><svg className={iconClass}><use xlinkHref={this.icon}/></svg></div>
        } else if (this.props.text && !this.props.icon) {
            toggle = <div onClick={evt => this.handleClick(evt)} className={this.props.class}>{this.text}</div>
        } else {
            toggle = <div onClick={evt => this.handleClick(evt)} className={this.props.class}>{this.text} <svg className={iconClass}><use xlinkHref={this.icon}/></svg></div>
        }



        return toggle;

    }
}

export default ToggleToggle;
