'use strict';

const ActionTypes = {
    SEARCH: 'SEARCH',
    SEARCH_RESULT: 'SEARCH_RESULT',
    INIT: 'INIT',
    FILTER_SHOW: 'FILTER_SHOW',
    FILTER_HIDE: 'FILTER_HIDE',
    FILTER_SELECT: 'FILTER_SELECT',
    SELECTED_FILTER_DELETE: 'SELECTED_FILTER_DELETE',
    FILTER_RESET: 'FILTER_RESET',
    FILTER_SEARCH: 'FILTER_SEARCH'
};

export default ActionTypes;
