import React from "react";
import Alert from "./Alerts";

class ListNote extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        text: this.props.value || ""
      };

      //this we need so we can use this.state in handleClick
      this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
      if (event.target === this.myModal) {
        this.myModal.close();
      }
    }

    render () {
      return (
        <>
          <dialog
            className="modal-container"
            ref={ref => this.myModal = ref}
            onClick={evt => this.handleClick(evt)}
          >
            <form method="dialog">

              <header className="modal-header">
                <h3 className="modal-heading">Write a note about {this.plant}</h3>
                <button className="modal-close" aria-label="Close modal">
                  <svg className="icon icon-gray"><use xlinkHref="/icons/__remixicon.sprite.svg#close-line"/></svg>
                </button>
              </header>

              <div className="modal-content">

                <div className="input-group mar-b">
                  <textarea
                    name="text"
                    value={this.state.text}
                    id="note"
                    onChange={event => {this.setState({text: event.target.value})}}
                    className="input-text input-u-text"
                    placeholder="Note a cultivar name or log your plantings"
                  />
                  <label htmlFor={this.props.type + "_note"} className="input-description">{this.props.desc}</label>

                    <div className="text-right mar-t-sm">
                      <input
                        type="submit"
                        onClick={event => {this.add(event)}}
                        value={"Save note"}
                        className="btn-secondary-dark"
                      />
                    </div>
                </div>

            </div>

            </form>
          </dialog>

          <a onClick={evt => this.myModal.showModal()}>Add a note</a>
        </>

        );
    }

    add (event) {
      event.preventDefault();

      var params = {id: this.props.id, public_note: this.state.text};

      fetch(this.props.list_url + ".json", {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.props.token
        },
        body: JSON.stringify({list: {list_entries_attributes: {"0": params}}})
      })
        .then(response => {
          if (response.ok) {
            this.myModal.close();
            var str = "✅ Note updated. Refresh to view.";
            Alert.show(str);
          } else {
            Alert.show("❌ Error saving note");
            throw {name: "ListNoteError", response: response};
          }
        });
    }
}

export default ListNote
