import React from "react";

function SearchFilterView(props) {
  const onShow = () => props.onFilterShow();
  const onHide = () => props.onFilterHide();
  const onClick = () => props.onFilterSearch(props.query);
  const onReset = () => props.onFilterReset();

  let filtersChanged = JSON.stringify(props.store.get("selectedFilters")) != JSON.stringify(props.store.get("activeFilters"));
  let selectedFiltersCount = props.store.get("selectedFilters").toArray().length

  return (
    <form>
      <button className="btn-secondary-dark -has-icon" type="button" onClick={evt => onShow(evt)}>
        <svg className="icon icon-primary-dark" aria-label="Filter"><use xlinkHref="/icons/__remixicon.sprite.svg#filter-3-line" /></svg>
        Filters {selectedFiltersCount > 0 ? "(" + selectedFiltersCount + ")" : ""}
      </button>

      <span className={"slideover-visibility-container" + (props.store.get("filtersToggled") ? " -is-hidden" : "")}>

        <div className="slideover-card-overlay" onClick={evt => onHide(evt)}></div>
        <aside className="slideover-card">
          <div className="slideover-action-group row pad-t-sm">
            <a className="slideover-close" onClick={evt => onHide(evt)} >
              <div className="-hide-bp-sm">&times;</div>
            </a>
          </div>

          <div className="slideover-content -full">
            <h3>
              <svg className="icon icon-primary-dark" aria-label="Filter"><use xlinkHref="/icons/__remixicon.sprite.svg#filter-3-line" /></svg>
              &nbsp;
              Filters
            </h3>

            <div className="tag-set">
              {props.store.get("selectedFilters").entrySeq().map((e) => <ActiveFilter key={e[0]} name={e[0]} value={e[1]} {...props} />)}
            </div>

            <div className="filter-list pad-b-lg">
              {props.store.get("availableFilters").entrySeq().map((f) => <AvailableFilter key={f[0]} name={f[0]} items={f[1]} {...props} />)}
            </div>
          </div>

          <div className="slideover-footer row row-gutters">
            <button type="reset" className="btn-clear link" onClick={onReset}>
              Reset filters
            </button>
            <button type="button" disabled={filtersChanged ? false : true} className="btn-primary" onClick={onClick}>
              Update search
            </button>
          </div>
        </aside>
      </span>
    </form>
  );
}

function ActiveFilter(props) {
  const onClick = () => props.onSelectedFilterDelete(props.name);

  return (
    <label className="tag-fill">
      {props.name}: {props.value.sort((a, b) => a - b).join(", ")} <a onClick={onClick} className="tag-close">×</a>
    </label>
  );
}

class AvailableFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: true
    };

  }

  render() {
    let active_items = this.props.items.filter(item => item.active).length;
    let classes = this.state.toggled ? "filter-header-row" : "filter-header-row -is-active";

    return (
      <section className="filter-section">
        <header className={classes} onClick={evt => { this.setState({ toggled: this.state.toggled ? false : true }) }}>
          {this.props.name == 'type' ? 'Type' : this.props.name} <div className="filter-header-meta">{active_items > 0 ? active_items : ""}</div> <svg className="icon icon-gray" aria-label=""><use xlinkHref={"/icons/__remixicon.sprite.svg#arrow-" + (this.state.toggled ? "right" : "down") + "-s-line"} /></svg>
        </header>
        {this.state.toggled ? "" :
          <div className="filter-item-list">
            {this.props.items.map(item => <AvailableFilterItem {...this.props} key={item.name} filter={this.props.name} {...item} />)}
          </div>
        }
      </section>
    );
  }
}

function AvailableFilterItem(props) {
  const change = (evt) => props.onFilterSelect(props.filter, props.name, evt.target.checked);

  return (
    <label htmlFor={props.name} className="filter-item">
      <input type="checkbox" id={props.name} onChange={evt => change(evt)} checked={props.active} />
      {props.name} <div className="filter-item-meta">{props.count}</div>
    </label>
  );
}

export default SearchFilterView;
