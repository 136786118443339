'use strict';

import ActionTypes from './ActionTypes';
import dispatcher from "packs/dispatcher";

const Actions = {
    search(q) {
        dispatcher.dispatch({
            type: ActionTypes.SEARCH,
            q: q,
        });
    },
    init(props) {
        dispatcher.dispatch({
            type: ActionTypes.INIT,
            props: props
        });
    },
    filterShow() {
        dispatcher.dispatch({
            type: ActionTypes.FILTER_SHOW
        })
    },
    filterHide() {
        dispatcher.dispatch({
            type: ActionTypes.FILTER_HIDE
        })
    },
    filterSelect(filter, value, checked) {
        dispatcher.dispatch({
            type: ActionTypes.FILTER_SELECT,
            name: filter,
            value: value,
            checked: checked
        })
    },
    selectedFilterDelete(name) {
        dispatcher.dispatch({
            type: ActionTypes.SELECTED_FILTER_DELETE,
            name: name
        })
    },
    filterReset() {
        dispatcher.dispatch({
            type: ActionTypes.FILTER_RESET
        })
    },
    filterSearch(query) {
        dispatcher.dispatch({
            type: ActionTypes.FILTER_SEARCH,
            query: query
        })
    }
};

export default Actions;
