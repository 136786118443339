import React from "react"
import Alert from "./Alerts";

class ListMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        }
    }

    render () {
        var message_box

        if (this.state.expanded) {
            message_box = <>
                            <p>message box here</p>
                          </>
        }

        var selected_count

        return (
            <>
            <a href="" onClick={event => {this.expand(event)}} className="btn-secondary-dark -mobile-full-width">
              {"Send a trade request" + (selected_count > 0 ? " (" + selected_count + ")" : "")}
            </a>
            {message_box}
            </>
        );
    }

    expand (event) {
        event.preventDefault();

        if (this.state.expanded) {
            this.setState({expanded: false})
        } else {
            this.setState({expanded: true})
        }
    }

    add (event) {
        event.preventDefault();

        var params

        if (this.props.type === "private") {
            params = {id: this.props.id, private_note: this.state.text}
        } else {
            params = {id: this.props.id, public_note: this.state.text}
        }

        fetch(this.props.list_url + ".json", {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': this.props.token
            },
            body: JSON.stringify({list: {list_entries_attributes: {"0": params}}})
        })
            .then(response => {
                if (response.ok) {
                    var str = "✅ " + this.props.type + " note updated";
                    Alert.show(str);
                } else {
                    Alert.show("❌ Error saving message");
                    throw {name: "ListNoteError", response: response}
                }
            })

    }
}

export default ListMessage
