import React from "react"
import PropTypes from "prop-types"

class ImagesForm extends React.Component {
    constructor(props) {
        super(props);

	this.state = {
            images: props.images
        };

        this.addFiles = this.addFiles.bind(this);
    }

    addFiles(files) {
        Array.from(files).forEach(file => {
            let imgs = this.state.images;

            imgs.push({uploading: file})

            this.setState({images: imgs});
        });
    }

    render () {
	return (
	    <React.Fragment>

	      {this.state.images.map((image, index) => {
                  // do not move index to image.id because this could break the ugly hack in strong params plants controller
		  return <Image key={index} index={index} {...image} {...this.props} />
	      })}

            <label className="btn-upload">
                <span className="btn-tertiary -has-icon"><svg className="icon icon-gray"><use xlinkHref="/icons/__remixicon.sprite.svg#upload-cloud-line"/></svg>Upload image</span>
              <input
                multiple="multiple"
                type="file"
                id="file_upload"
                onChange={evt => this.addFiles(evt.target.files)}
                accept="image/png, image/jpeg"
              />

            </label>
	    </React.Fragment>
	);
    }
}

class Image extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            uploading: this.props.uploading ? true : false,
            thumb_url: this.props.thumb_url,
            id: this.props.id,
            description: this.props.description,
            source: this.props.source
        };

        if(this.props.uploading) {
            this.upload()
        }
    }

    upload() {
        const formData = new FormData();
        formData.append("image[file]", this.props.uploading);

        fetch('/images', {
            method: 'POST',
            headers: {
                'X-CSRF-Token': this.props.token,
                'Accept': 'application/json'
            },
            body: formData
        }).then(response => response.json().then(
            json => {
                if (response.ok) {
                    return json;
                } else {
                    throw new Error(json.file)
                }
            }
        )).then(
            success => {
                this.setState(success);
                this.setState({uploading: false});
            }
        ).catch(
            error => this.setState({error: error.message, uploading: false})
        );
    }


    render () {
	var imageField

        if(this.state.error) {
            // the response after the upload was not ok, this could mean that the user updated a broken image we cannot process
            // this.state.error includes the error message from the backend
            // SIMON: to test that, there is a broken image in test/fixtures/files/broken.jpeg

            imageField = <p>Error uploading: {this.state.error}</p>;
        } else if (this.state.uploading) {
            // this state is temporary while uploading
            // SIMON: we would need to show a spinner or something
            imageField = <p>[Uploading...]</p>
        } else if (this.state._destroy) {
            // this is after the X and/or `delete image` was clicked
            // we don't need to render anything so the id does not appear in the images_ids array params
	    imageField = "";
	} else {
            // this is the existing image state
	    imageField = <>
                        <div className="img-preview-container row row-between align-center mar-b" key={this.state.id}>
                            <input type="hidden" name={this.props.model_name + "[image_ids][]"} value={this.state.id} />
                            <div className="img-preview pad">
                            <img src={this.state.thumb_url} />
                            </div>
                            <a className="btn-icon-clear-danger mar-r" onClick={evt => this.deleteImage(evt)} href="">
                                <svg className="icon icon-gray"><use xlinkHref="/icons/__remixicon.sprite.svg#delete-bin-line"/></svg>
                            </a>
                        </div>
                        {this.props.preview == "minimal" ?
                        ""
                        :
                        <div className="mar-b row row-gutters responsive-sm">
                            <input type="hidden" name={this.props.model_name + "[images_attributes["+this.props.index+"][id]"} value={this.state.id} />
                            <div className="col-50">
                                <input type="text"  name={this.props.model_name + "[images_attributes["+this.props.index+"][description]"} className="input-text" placeholder="Image description" defaultValue={this.state.description} />
                            </div>
                            <div className="col-50">
                                <input type="text" name={this.props.model_name + "[images_attributes]["+this.props.index+"][source]"} className="input-text mar-t" placeholder="Link to image source" defaultValue={this.state.source} />
                            </div>
                        </div>
                        }
                    </>
	}
	return <>
	           {imageField}
	         </>

    }

    deleteImage(evt) {
	evt.preventDefault()
	this.setState({_destroy: true})
    }
}

export default ImagesForm
