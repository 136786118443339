import { MeiliSearch } from 'meilisearch'
import React from "react"
import L from "leaflet"

const client = new MeiliSearch({
  host: location.protocol + '//' + location.host,
})

const itemsIndex = client.index('Marketplace_Item_' + process.env.RAILS_ENV);

class MarketplaceMap extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchOnMove: true,
            bbox: this.props.bbox,
            items: []
        }

        this.markers = null;
        this.mymap = null;
        this.handleMove = this.handleMove.bind(this);
        this.toggleSearchOnMove = this.toggleSearchOnMove.bind(this);
        this.search = this.search.bind(this);
    }

    componentDidMount() {
        if (this.state.bbox) {
            this.mymap = L.map('mapid').fitBounds([
                [this.state.bbox[0], this.state.bbox[1]],
                [this.state.bbox[2], this.state.bbox[3]]
            ]).setZoom(this.state.bbox[4]);
        } else {
            this.mymap = L.map('mapid').setView([30, -10], 2);
        }

        L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicGVybWFwZW9wbGUiLCJhIjoiY2xndjg5empjMDE3bDNrcGxqazN4eWQxYiJ9.TTzaA931zw2NhANr-tXuuA', {
            minZoom: 2,
            maxZoom: 18,
            attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            id: 'mapbox/streets-v11',
            tileSize: 512,
            zoomOffset: -1
        }).addTo(this.mymap);

        this.markers = L.layerGroup();
        this.mymap.addLayer(this.markers);

        this.search();

        this.mymap.on('moveend', this.handleMove);

        document.addEventListener("turbolinks:load", ()=> {

            if (Turbolinks.scroll) {
                document.scrollingElement.scrollTo(0, Turbolinks.scroll);
            }

            Turbolinks.scroll = 0;
        });
    }

    componentWillUnmount() {
        this.mymap.off('moveend', this.handleMove);
    }

    render () {
        return <>
                 <div id="mapid" className="container-map"></div>
                 <label className="pad-h pad-v-sm"><input type="checkbox" checked={this.state.searchOnMove} onChange={this.toggleSearchOnMove} /><div className="input-label mar-l">Search as I move the map</div></label>
               </>
    }

    updateMap() {
        this.markers.clearLayers();
        this.state.items.map(item => {
            this.markers.addLayer(L.marker([item._geo.lat, item._geo.lng])
                                //   .bindPopup("<div class='map-popup'>" + "<img src='" + item.thumb + "'/>" + "<div class='map-popup-content'>" + "<label class='map-popup-title'>" + item.name + "</label>" + "<div class='map-popup-price'>$" + item.price + "</div></div> <a class='map-popup-btn' href='" + item.link + "'>View</a>" + "</div>")

                                .bindPopup("<div class='map-popup'>" + "<div class='map-popup-content'>" + "<label class='map-popup-title'>" + item.seller + "</label>" + "</div>" + "<a class='map-popup-btn' href='/marketplace?seller=" + item.seller + "'>View seller</a>" + "</div>")
                                 );
        })
    }

    handleMove() {
        var bounds = this.mymap.getBounds();
        var zoom = this.mymap.getZoom();
        var bbox = [bounds.getNorthWest().lat,bounds.getNorthWest().lng,bounds.getSouthEast().lat,bounds.getSouthEast().lng,zoom]

        this.setState({bbox: bbox})

        if (this.state.searchOnMove) {
            var loc = window.location.toString();

            if (/bbox\=[^\&]*/.test(window.location.search)) {
                loc = loc.replace(/bbox\=[^\&]*/, "bbox=" + bbox.join(","))
            } else {
                var separator = (window.location.search) ? "&" : "?"
                loc = window.location + separator + "bbox=" + bbox.join(",")
            }

            //window.history.pushState({},"", loc);
            //const event = new Event('permapeople');
            //document.dispatchEvent(event);
            Turbolinks.scroll = document.scrollingElement.scrollTop;
            Turbolinks.visit(loc)

            this.search()
        }
    }

    search() {
      var params = this.props.p

      // disable paging on the map
      params.hitsPerPage = 2000

      if (this.state.bbox) {
        // remove zoom from bbox array
        var bbox_without_zoom = this.state.bbox
        bbox_without_zoom.splice(-1,1)

        // clamp to [-180,180]
        bbox_without_zoom = bbox_without_zoom.map(x => Math.min(Math.max(x, -180.0), 180.0))
        params.filter = '_geoBoundingBox([' + bbox_without_zoom[0] + ',' + bbox_without_zoom[3] + '],[' + bbox_without_zoom[2] + ',' + bbox_without_zoom[1] + '])'
      }

      itemsIndex.search(this.props.q, params).then((hits) => {
        this.setState({items: hits.hits});
        this.updateMap()
      });
    }

    toggleSearchOnMove() {
        if (this.state.searchOnMove) {
            this.setState({searchOnMove: false })
        } else {
            this.setState({searchOnMove: true })
        }
    }
}

export default MarketplaceMap
