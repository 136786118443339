'use strict';

import Immutable from 'immutable';
import { ReduceStore } from 'flux/utils';
import ActionTypes from './ActionTypes';
import dispatcher from "packs/dispatcher";

import * as Sentry from '@sentry/react'
Sentry.init({
    dsn: process.env.SENTRY_URL,
    environment: process.env.RAILS_ENV
});

class SearchStore extends ReduceStore {
    constructor() {
        super(dispatcher);
    }

    getInitialState() {
        return Immutable.OrderedMap({
            filtersToggled: true,
            activeFilters: Immutable.OrderedMap(),
            selectedFilters: Immutable.OrderedMap(),
            availableFilters: Immutable.OrderedMap()
        });
    }

    reduce(state, action) {
        switch (action.type) {
            case ActionTypes.INIT:
                return state
                    .set("activeFilters", Immutable.OrderedMap(action.props.active_filters))
                    .set("selectedFilters", Immutable.OrderedMap(action.props.active_filters)) // setting this to the same, these are the currently selected but not active
                    .set("availableFilters", Immutable.OrderedMap(action.props.available_filters))
            case ActionTypes.FILTER_SHOW:
                return state.set("filtersToggled", false)
            case ActionTypes.FILTER_HIDE:
                return state.set("filtersToggled", true)
            case ActionTypes.FILTER_SELECT:
                // this whole thing only exists to set the active state of the available items
                let indexOfListToUpdate = state.get('availableFilters').get(action.name).findIndex(listItem => {
                    return listItem.name === action.value;
                });

                if (action.checked) {
                    let obj = { selectedFilters: {} }
                    obj.selectedFilters[action.name] = [action.value]

                    return state.mergeDeep(obj).setIn(["availableFilters", action.name, indexOfListToUpdate, "active"], true)
                    // if (typeof(vals) == "undefined") {
                    //     return state.set("selectedFilters", obj.set(action.name, [action.value]))
                    // } else {
                    //     // this works only like that and I have no idea why
                    //     obj = obj.update(action.name, myList => myList.push(action.value));
                    //     return state.set("selectedFilters", obj.set(action.name, vals))
                    // }
                } else {
                    //this means a filter item got deselected
                    let obj = state.get("selectedFilters")
                    let vals = obj.get(action.name)
                    let newValues = vals.filter(i => i != action.value)

                    if (newValues.length > 0) {
                        // there are still some values left for that filter
                        return state.set("selectedFilters", obj.set(action.name, newValues)).setIn(["availableFilters", action.name, indexOfListToUpdate, "active"], false)
                    } else {
                        // the filter does not have any options checked anymore and can be removed
                        return state.set("selectedFilters", obj.remove(action.name)).setIn(["availableFilters", action.name, indexOfListToUpdate, "active"], false)
                    }
                }
            case ActionTypes.SELECTED_FILTER_DELETE:
                return state.set("selectedFilters", state.get("selectedFilters").remove(action.name))
                    .updateIn(["availableFilters", action.name], items => items.map(item => { item.active = false; return item; }))
            case ActionTypes.FILTER_RESET:
                return state.set("selectedFilters", Immutable.OrderedMap())
                    .updateIn(["availableFilters"], filter => filter.map(items => items.map(item => { item.active = false; return item; })))
            case ActionTypes.FILTER_SEARCH:
                const queryString = new URLSearchParams();

                if (action.query) {
                    queryString.append("q", action.query);
                }

                state.get("selectedFilters").map((values, item) => {
                    let marker = values.length > 1 ? "[]" : "";

                    values.map(val => queryString.append(item + marker, val));
                });

                Turbolinks.visit("/search" + (queryString.toString() != "" ? "?" : "") + queryString.toString());
                return state.set("filtersToggled", true)
            default:
                return state;
        }
    }
}

export default new SearchStore();
