'use strict';

import Immutable from 'immutable';
import { ReduceStore } from 'flux/utils';
import ActionTypes from './ActionTypes';
import dispatcher from "packs/dispatcher";
import Alert from "./Alerts";
import Loader from "./Loader";

import { MeiliSearch } from 'meilisearch'

const client = new MeiliSearch({
    host: location.protocol + '//' + location.host
})

import * as Sentry from '@sentry/react'
Sentry.init({
    dsn: process.env.SENTRY_URL,
    environment: process.env.RAILS_ENV
});

const index = client.index('Plant_' + process.env.RAILS_ENV);

class Store extends ReduceStore {
    constructor() {
        super(dispatcher);
    }

    getInitialState() {
        return Immutable.OrderedMap({ pattern: {} });
    }


    reduce(state, action) {
        switch (action.type) {
            case ActionTypes.SEARCH:
                // return if there is no q
                if (action.q == "") {
                    return state.set("hits", undefined)
                }

                index.search(action.q, {
                    hitsPerPage: 10,
                }).then((hits) => {
                    dispatcher.dispatch({ type: ActionTypes.SEARCH_RESULT, hits: hits.hits });
                });

                return state.set("q", action.q);
            case ActionTypes.SEARCH_RESULT:
                return state.set("hits", action.hits).set("user", action.user).set("queryID", action.queryID);
            default:
                return state;
        }
    }
}

export default new Store();
