import React from "react"
import SearchField from "./SearchField"

class SearchDropdownToggle extends React.Component {
    constructor(props) {
        super(props);

        this.state = {toggled: false}

        //this we need so we can use this.state in handleClick
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {

        this.target = document.getElementById("dd-search");

    }


    handleClick(event) {

        if (this.props.toggleClass) {
            this.target.classList.toggle(this.props.toggleClass)
        } else {
            this.target.classList.toggle('-is-toggled')
        }

        this.setState({toggled: !this.state.toggled});

    }



    render () {

        <>
            <div onClick={evt => this.handleClick(evt)}><svg className="icon"><use xlinkHref="/icons/__remixicon.sprite.svg#search-line"/></svg></div>

            <div class="nav-list-dropdown" id="dd-search">
                <div class="dropdown-wrapper">
                    <SearchField />
                </div>
            </div>
        </>

    }
}

export default SearchDropdownToggle;
