import React from "react"

class NavToggle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {open: false}

        //this we need so we can use this.state in handleClick
        this.handleClick = this.handleClick.bind(this);

        this.menu = document.getElementById("mobile-nav");

        this.asset = this.props.asset;

        this.icon = "/icons/__remixicon.sprite.svg#" + this.props.icon;

        this.classList = this.props.class;

    }


    handleClick(event) {

        this.menu.classList.toggle('-is-open');
        // document.body.classList.toggle('-is-locked')
        // event.target.classList.toggle('-is-toggled');

    }



    render () {

        var btn = null;

        if (this.props.icon && !this.props.text) {
            btn = <svg className={this.classList} onClick={evt => this.handleClick(evt)}><use xlinkHref={this.icon}/></svg>
        } else if (this.props.text) {
            btn = <a onClick={evt => this.handleClick(evt)} className={this.classList}>{this.props.text}</a>
        } else if (this.props.asset && !this.props.text) {
            btn = <a onClick={evt => this.handleClick(evt)}><img src={this.asset} className={this.classList} alt="Close navigation menu"/></a>
        } else {
            btn = <a onClick={evt => this.handleClick(evt)} aria-label="Toggle navigation menu" className={this.classList}></a>
        }

        return btn;

    }
}

export default NavToggle;
