import React from "react";
import Alert from "./Alerts";

class PlantAddToList extends React.Component {
    constructor(props) {

        super(props);
        // move this to this.state because
        // we want to add to it if form is submitted
        this.state = {
            success: false
        }
    }

    render () {
	return (
        <div className="btn-tertiary btn-u-block -has-icon select-btn-wrapper">
            <select className="select-input" name="plant_add_to_list" onChange={evt => this.addToList(evt)} aria-label="Select a list">
                <optgroup>
                    <option key="0" value="">+ Add to list...</option>
                    {this.props.lists.map(list => {
                        return <option key={list.id} value={list.slug}>{list.name}</option>
                    })}
                </optgroup>
            </select>
            <svg className="icon icon-gray" aria-label="Add plant to list"><use xlinkHref="/icons/__remixicon.sprite.svg#play-list-add-fill"/></svg>
            Add to list
        </div>
	);
    }

    addToList(evt) {
        evt.preventDefault()

        if (evt.target.value == "") {
            return true
        }

        fetch("/" + this.props.username + "/" + evt.target.value + "/add", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': this.props.token
            },
            body: JSON.stringify({list: {plant_id: this.props.plant_id}})
        })
            .then(response => {
                if (response.ok) {
                    var str = "✅ Added plant to list";
                    Alert.show(str);
                } else {
                    throw {name: "AddToListError", response: response}
                }
            })
    }
}
export default PlantAddToList
