'use strict';

import SearchView from "./SearchView";
import {Container} from 'flux/utils';
import Store from './Store';
import SearchActions from './Actions';

export default Container.createFunctional(
  SearchView,
  () => [Store],
  (prevState, props) => ({...props, store: Store.getState(), onSearch: SearchActions.search}),
  {withProps: true}
)
