
const LoadingState = {

    set(loading, el) {

        if (loading == true) {
            document.querySelector(el).classList.add("-is-loading");
            document.querySelector(el).setAttribute("disabled", true);
        } else {
            document.querySelector(el).classList.remove("-is-loading");
            document.querySelector(el).removeAttribute("disabled");
        }

    }

}

export default LoadingState;