
const Alert = {

    show(txt, time) {
        const alert = document.getElementById("alert");

        if (time == null) {
            time = 5000;
        }

        alert.innerText = txt;

        setTimeout(() => {
            alert.innerText = "";
        }, time);
    }

}

export default Alert;