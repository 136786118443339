import React, {useState, useEffect} from "react"
import SearchField from "./SearchField"

function SearchView(props) {
    return (
        <div className="dropdown-wrapper">
          <SearchField {...props} />
          <Hits {...props} />
        </div>
    );
}

function Hits(props) {
    var hits = null

    if(props.store.get("hits")) {
        if (props.store.get("hits").length == 0 && props.store.get("q")) {
            hits = <div className="dropdown input-search-result"><small>Nothing found. <a href="/plants/new">Add plant</a></small></div>
        } else {
            hits = <div className="dropdown">{props.store.get("hits").map((hit, index) => {return <Hit {...props} key={index} hit={hit} user={props.store.get("user")} queryID={props.store.get("queryID")} position={index} />})}</div>
        }
    }
    return hits
}

function Hit(props) {

    var plant = props.hit
    var name = plant.type == 'Variety' ? plant.parent_name + ' "' + plant.name + '"' : plant.name
    var title = name + " - " + plant.scientific_name + " (" + plant.type + ")"
    var tag = plant.type == 'Variety' ? <div className="tag-var">Variety</div> : <div className="tag-sp">Species</div>

    if (props.minimal == true){
        return (
            <a data-turbolinks="false" className="input-search-result -sm" href={plant.link} title={title}>
                <label className="input-search-result-name"> {name} {tag}<span>{plant.scientific_name}</span></label>
            </a>
        );
    } else {
        return (
            <a data-turbolinks="false" className="input-search-result" href={plant.link} title={title}>
                <img className="data-img-xs" src={plant.thumb} />
                <label className="input-search-result-name"> {name} {tag}<span>{plant.scientific_name}</span></label>
            </a>
        );
    }
}

export default SearchView;
