import React from "react"
import { MeiliSearch } from 'meilisearch'

const client = new MeiliSearch({
  host: location.protocol + '//' + location.host,
})

const plantsIndex = client.index('Plant_' + process.env.RAILS_ENV);

class PlantSelector extends React.Component {
    constructor(props) {
        super(props);

	this.state = {
	    objects: this.props.objects || [],
	    hits: [],
	    value: ""
	}

	this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
    }

    handleClick(event) {
        if(!event.target.closest('#result')) {
            this.setState({hits: []})
        }
    }

    render () {
	return (
	    <>
              {
                  this.state.objects.map((obj, index) => {
                      // react needs a unique key but you cannot reuse it in the object so we need to assign a second time as id :facepalm:
                      return <SelectedObject key={index} id={index} obj={obj} basename={this.props.multiple && this.props.basename.includes("[") ? this.props.basename + "[" + index + "]" : this.props.basename} fieldname={this.props.fieldname} persisted={obj.id ? true : false} multiple={this.props.multiple} />
                  }
                                        )
              }

	      <div className="mar-b dropdown-wrapper">

                {(this.props.multiple || this.state.objects.filter(obj => obj.destroyed !== true).length == 0) ? <input
                    id="plants_input"
                    type="text"
                    className="input-text"
                    placeholder={this.props.multiple ? "Search for plants..." : "Search for a plant..."}
                    autoComplete="off"
                    onChange={evt => this.search(evt)}
                /> : null}

                {this.state.hits.length > 0 ? <div className="dropdown">
                                                <div id="result">{this.state.hits.map(hit => {
                                                    return <div key={hit.id} className="input-search-result">
                                                                <a onClick={evt => this.addObject(evt, hit)} href="">
                                                                    <img className="data-img-xs" src={hit.thumb} />
                                                                    <label className="input-search-result-name"> {hit.name}
                                                                        <span>{hit.scientific_name}</span>
                                                                    </label>
                                                                </a>
                                                            </div>})}
                                                </div>
                                              </div> : null}
              </div>
	    </>
	);
    }

  search(evt) {
        plantsIndex.search(evt.target.value, {
            hitsPerPage: 20
        }).then(({ hits }) => {
            this.setState({hits: hits});
        });
    }

    addObject(evt, hit) {
        evt.preventDefault();

        this.state.objects.push({name: hit.name, thumb: hit.thumb, plant_id: hit.id, coordinates: []});

	this.setState({
            objects: this.state.objects,
            hits: [],
            value: ""
        })
    }
}

class SelectedObject extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            destroyed: false
        }
    }

    render () {
        var ui;

        if (this.state.destroyed) {
            // the react UI object is destroyed, can we just omit it or do we need to send explicit destroy params to the backend?
            if (this.props.persisted) {
                if (this.props.multiple) {
                    ui = <>
                       <input type="hidden" name={this.props.basename + "[id]"} value={this.props.obj.id} />
                       <input type="hidden" name={this.props.basename + "[_destroy]"} value="true" />

                         </>
                } else {
                    ui = <input type="hidden" name={this.props.basename + this.props.fieldname} />
                }
            } else {
                //just don't render anything since it was not persisted or doesn't need to be explicitly destroyed
                ui = ""
            }
        } else {
            ui = <>
                    <div className="img-preview-container row row-between align-center pad-sm mar-b-sm">
                        <div>
                            <img className="data-img-xs" src={this.props.obj.thumb}/>
                            <span className="pad-l">
                                {this.props.obj.name}
                            </span>
                        </div>
                        <a className="btn-icon-clear-danger" onClick={evt => this.remove(evt)} href="">
                            <svg className="icon"><use xlinkHref="/icons/__remixicon.sprite.svg#close-line"/></svg>
                        </a>
                    </div>

                    <input type="hidden" name={this.props.basename + this.props.fieldname} value={this.props.obj.plant_id || this.props.obj.id} />

                   {this.props.obj.public_note ? <input type="hidden" name={this.props.basename + "[public_note]"} value={this.props.obj.public_note} /> : ""}
                   {this.props.obj.group ? <input type="hidden" name={this.props.basename + "[group]"} value={this.props.obj.group} /> : ""}

                   {this.props.obj.coordinates ? this.props.obj.coordinates.map((coord, index) => {return <input type="hidden" key={index} name={this.props.basename + "[coordinates][]"} value={coord} />}) : ""}
                    {this.props.persisted && this.props.multiple? <input type="hidden" name={this.props.basename + "[id]"} value={this.props.obj.id} /> : null}
            </>
        }

        return ui
    }

    remove (evt) {
        evt.preventDefault();

        // we need this so the parent component knows when to show or not the input field
        this.props.obj.destroyed = true;

        this.setState({destroyed: true})
    }
}

export default PlantSelector
