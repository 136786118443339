import React from "react"

class ItemDeactivate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {open: false}
        // this we need so we can use this.state in handleClick
        this.handleClick = this.handleClick.bind(this);
        this.deactivate = this.deactivate.bind(this);
    }

    render () {
        var ui;

        if (this.state.open) {
            ui = <form noValidate onSubmit={evt => this.deactivate(evt)} className="input-group mar-t">
                    <label className="input-label" for="msg">Reason for deactivation</label>
                    <textarea
                       className="input-text"
                       name="msg"
                       placeholder="Did you sell it on permapeople marketplace?"
                    />
                    <div className="text-right pad-t">
                        <input type="submit" className="btn-primary" value="Deactivate" />
                    </div>
                </form>
        } else {
            if (this.state.sent) {
            } else {
                ui = <><a className="btn-secondary-danger" onClick={evt => this.handleClick(evt)} href={"/marketplace/items/" + this.props.slug + "/toggle"}>Deactivate</a></>;
            }
        }

        return <>{ui}</>
    }

    handleClick(evt) {
        evt.preventDefault()

        if (this.state.open) {
            this.setState({open: false})
        } else {
            this.setState({open: true})
        }
    }

    deactivate(evt) {
        evt.preventDefault()
        fetch("/marketplace/items/" + this.props.slug + "/toggle", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': this.props.token
            },
            body: JSON.stringify({msg: evt.target.childNodes[0].value})
        })
        .then(response => {
            if (response.ok) {
                this.setState({open: false, sent: true})
                location.reload();
            }
        })
    }
}

export default ItemDeactivate
