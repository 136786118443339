import React from "react"

class Sort extends React.Component {
    render () {
        var current = ""
        if (window.location.search.match(/sort\=([^\&]*)/)) {
                current = window.location.search.match(/sort\=([^\&]*)/)[1]
        }

        return <select defaultValue={current} onChange={evt => this.change(evt.target.value)} className="input-select" name="sort" id="sort-select">
                 {this.props.options.map(option => {return <option key={option.value + option.name} value={option.value}>{option.name}</option>})}
        </select>
    }

    change(value) {
        var loc = window.location.toString()

        if (/sort\=[^\&]*/.test(window.location.search)) {
            if (value) {
                loc = loc.replace(/sort\=[^\&]*/, "sort=" + value)
            } else {
                if (/sort\=[^\&]*\&{1}/.test(window.location.search)) {
                    loc = loc.replace(/[\?\&]?sort\=[^\&]*\&{1}/, "?")
                } else {
                    loc = loc.replace(/[\?\&]?sort\=[^\&]*/, "")
                }
            }
        } else {
            if (value) {
                var separator = (window.location.search) ? "&" : "?"
                loc = window.location + separator + "sort=" + value
            }
        }

        window.location.replace(loc);
    }
}

export default Sort
