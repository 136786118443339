import { MeiliSearch } from 'meilisearch'
import React from "react"
import PropTypes from "prop-types"


const client = new MeiliSearch({
    host: location.protocol + '//' + location.host,
})

const plantsIndex = client.index('Plant_' + process.env.RAILS_ENV);
const plantLinksIndex = client.index('PlantLinks_' + process.env.RAILS_ENV);

class PlantLinks extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            links: this.props.links.map(link => {
                link.persisted = link.id ? true : false
                link.hitsLinkType = []
                link.hitsToPlant = []
                return <Link key={link.id} link={link} />
            })
        }

        // find the highest id coming from the backend and increase by one
        this.state.counter = this.state.links.map((l) => l.props.link.id).sort((a, b) => b - a)[0] + 1
    }

    render() {
        return (
            <>
                {this.state.links.map(link => {
                    return link
                })}
                <a href="" className="btn-secondary-dark" onClick={evt => this.addLink(evt)}>Add plant relation</a>
            </>
        );
    }

    addLink(evt) {
        evt.preventDefault()
        // add a new link with some basic state
        this.state.links.push(<Link key={this.state.counter} link={{ link_type: "", hitsLinkType: [], hitsToPlant: [], to_plant: {} }} />)
        this.state.counter++
        this.setState(this.state)
    }
}

class Link extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.props.link

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClick);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
    }

    handleClick(event) {
        if (!event.target.closest('#resultLinkType') && !event.target.closest('#plant_link_link_type')) {
            this.setState({ hitsLinkType: [] })
        }

        if (!event.target.closest('#resultToPlant') && !event.target.closest('#plant_link_to_plant')) {
            this.setState({ hitsToPlant: [] })
        }
    }

    render() {
        var delField
        // if link is set to _destroy then don't render anything beside
        // whats needed for the backend to actually delete it
        // otherwise show the delete link and continue
        if (this.state._destroy) {
            // only render the stuff for the backend if the link is persisted
            // otherwise it was just created and removed immediatly before persisting
            // and therefor we can just not render anything because nothing needs to be
            // sent to the backend
            if (this.state.persisted) {
                return <React.Fragment key={this.key}>
                    <input type="hidden" name="plant[from_plant_links_attributes][][id]" value={this.state.id} />
                    <input type="hidden" name="plant[from_plant_links_attributes][][_destroy]" value="true" />
                </React.Fragment>
            }

            return null
        } else {
            delField = <div className="col-10 text-right">
                <button className="btn-icon-clear-danger" onClick={evt => this.setDestroy()} type="button">
                    <svg className="icon icon-gray"><use xlinkHref="/icons/__remixicon.sprite.svg#delete-bin-line" /></svg>
                </button>
            </div>

        }

        var persistedField
        // if this is a persisted record, always send the plant link id with it
        if (this.state.persisted) {
            persistedField = <input type="hidden" name="plant[from_plant_links_attributes][][id]" value={this.state.id} />
        }

        // console.log("::: ", this.state.to_plant)

        // only set a name (=send it to the backend) if there is anything to send
        var linkTypeField = <>
            <div className="col-90 dropdown-wrapper">
                <input
                    id="plant_link_link_type"
                    required
                    name={this.state.link_type && this.state.to_plant_id ? "plant[from_plant_links_attributes][][link_type]" : null}
                    key="link_type"
                    type="text"
                    className="input-text"
                    placeholder="Describe the connection"
                    autoComplete="off"
                    onFocus={evt => this.searchLinkType(evt)}
                    onChange={evt => this.searchLinkType(evt)}
                    value={this.state.link_type}
                />
                {this.state.hitsLinkType && this.state.hitsLinkType.length > 0 ? <div id="resultLinkType" className="dropdown text-left">{this.state.hitsLinkType.map(hit => { return <a key={hit.id} onClick={evt => this.updateLinkType(evt, hit)}><div>{hit.link_type}</div></a> })}</div> : null}
            </div>
        </>

        var toPlantField
        // shows either a plant to remove or an input field to add a plant to it
        if (this.state.to_plant_id) {
            toPlantField = <div className="img-preview-container row row-between align-center pad-sm">
                <input id="plant_link_to_plant" type="hidden" name="plant[from_plant_links_attributes][][to_plant_id]" value={this.state.to_plant_id} />
                <div>
                    <img className="data-img-xs" src={this.state.to_plant.thumb} />
                    <span className="pad-l">
                        {this.state.to_plant.name}
                    </span>
                </div>
                <a className="btn-icon-clear-danger" onClick={evt => this.removeToPlant()} href="">
                    <svg className="icon"><use xlinkHref="/icons/__remixicon.sprite.svg#close-line" /></svg>
                </a>
            </div>
        } else {
            toPlantField = <>
                <div className="dropdown-wrapper">
                    <input
                        required
                        id="plant_link_to_plant"
                        key="plant"
                        className="input-text"
                        placeholder="Search for plants here"
                        type="text"
                        onFocus={evt => this.searchToPlant(evt)}
                        onChange={evt => this.searchToPlant(evt)}
                    />
                    {this.state.hitsToPlant && this.state.hitsToPlant.length > 0 ? <div id="resultToPlant" className="dropdown text-left">{this.state.hitsToPlant.map(hit => {
                        return <div key={hit.id} className="input-search-result">
                            <a onClick={evt => this.updateToPlant(evt, hit)}>
                                <img className="data-img-xs" src={hit.thumb} />
                                <label className="input-search-result-name"> {hit.name} <span>{hit.scientific_name}</span></label>
                            </a>
                        </div>
                    })
                    }</div> : null}
                </div>
            </>

        }
        return <div className="border-box mar-b"><div className="row row-between mar-b">{linkTypeField}{delField}</div>{toPlantField}{persistedField}</div>
    }

    setDestroy() {
        this.setState({ _destroy: true })
    }

    removeToPlant() {
        delete this.state.to_plant_id
        this.state.to_plant = {}
        this.setState(this.state)
    }

    searchToPlant(evt) {
        plantsIndex.search(evt.target.value, {
            hitsPerPage: 10
        }).then(({ hits }) => {
            this.setState({ hitsToPlant: hits });
        });
    }

    updateToPlant(evt, hit) {
        evt.preventDefault()
        this.setState({ to_plant_id: hit.id, to_plant: { name: hit.name, thumb: hit.thumb }, hitsToPlant: [] })
    }

    searchLinkType(evt) {
        // we need this line so the input keeps
        // updating with the current things we type
        this.setState({ link_type: evt.target.value })

        plantLinksIndex.search(evt.target.value, {
            hitsPerPage: 10,
            distinct: 1
        }).then(({ hits }) => {
            this.setState({ hitsLinkType: hits });
        });
    }

    updateLinkType(evt, hit) {
        evt.preventDefault()
        this.setState({ link_type: hit.link_type, hitsLinkType: [] })
    }
}

export default PlantLinks
