import React from "react"

class LocationField extends React.Component {

  constructor(props) {
    super(props);
    this.state = {lat: this.props.latitude, lon: this.props.longitude};
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
  }

  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(this.autocompleteInput.current,
                                                            {"types": ["geocode"]});

    this.autocomplete.setFields(["geometry"]);
    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }

  handlePlaceChanged(){
    const place = this.autocomplete.getPlace();
      this.setState({
          lat: place.geometry.location.lat(),
          lon: place.geometry.location.lng()
      })
  }

  render() {
      return (
          <>
        <input
          ref={this.autocompleteInput}
          id={this.props.model + "_" + this.props.field}
          placeholder="Enter your location"
          type="text"
          name={this.props.model + "[" + this.props.field + "]"}
          className="input-text"
          required={this.props.required ? "required" : ""}
          autoComplete="off"
          defaultValue={this.props.location}
        />
            <label className="input-description">{this.props.explanation}</label>
            <input type="hidden" id={this.props.model + "_latitude"} name={this.props.model + "[latitude]"} defaultValue={this.state.lat} />
            <input type="hidden" id={this.props.model + "_longitude"} name={this.props.model + "[longitude]"} defaultValue={this.state.lon} />

          </>
    );
  }
}

export default LocationField
