import React from "react"

class MarketplaceAddress extends React.Component {

  constructor(props) {
    super(props);
    this.state = {open_for_trade: this.props.open_for_trade, in_search_of: this.props.in_search_of};
    this.toggle = this.toggle.bind(this);
  }

    toggle(evt){
      this.setState({open_for_trade: evt.target.checked})
    }

    render() {
        var textfield
        if (this.state.open_for_trade) {
            textfield = <div className="mar-v">
                          <textarea
                            id="marketplace_item_in_search_of"
                            placeholder="What are you looking to trade for?"
                            type="text"
                            name="marketplace_item[in_search_of]"
                            className="input-text"
                            defaultValue={this.props.in_search_of}
            ></textarea></div>
        } else {
            textfield = <input type="hidden" name="marketplace_item[open_for_trade]" id="marketplace_item_open_for_trade" value="false" />
        }

        return (
          <>
            <input type="checkbox" defaultChecked={this.state.open_for_trade} value={this.state.open_for_trade ? "1" : "0"}  name="marketplace_item[open_for_trade]" id="marketplace_item_open_for_trade" onChange={evt => this.toggle(evt) }/>
            {textfield}
          </>
        );
    }
}

export default MarketplaceAddress
