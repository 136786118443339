import React from "react";
import Alert from "./Alerts";

class ListGroup extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        text: this.props.value || ""
      };

      //this we need so we can use this.state in handleClick
      this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event) {
      if (event.target === this.myModal) {
        this.myModal.close();
      }
    }

    render () {
      return (
        <>
          <dialog
            className="modal-container"
            ref={ref => this.myModal = ref}
            onClick={evt => this.handleClick(evt)}
          >
            <form method="dialog">

              <header className="modal-header">
                <h3 className="modal-heading">Add {this.plant} to a group</h3>
                <button className="modal-close" value="cancel" aria-label="Close modal">
                  <svg className="icon icon-gray"><use xlinkHref="/icons/__remixicon.sprite.svg#close-line"/></svg>
                </button>
              </header>

              <div className="modal-content">
                <div className="input-group">
                    <p>⚠️ To remove group, leave field empty.</p>
                </div>
                <div className="input-group">
                  <input
                    name="text"
                    value={this.state.text}
                    className="input-text"
                    id="group"
                    onChange={event => {this.setState({text: event.target.value})}}
                    placeholder="Group name"
                />
                <div className="text-right mar-t-sm">
                  <input
                    type="submit"
                    onClick={event => {this.add(event)}}
                    value="Save"
                    className="btn-secondary-dark"
                  />
                </div>
            </div>
          </div>

          </form>

        </dialog>

          <a onClick={evt => this.myModal.showModal()}>Add to group</a>
        </>
        );
    }

    add (event) {
      event.preventDefault();

      fetch(this.props.list_url + ".json", {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.props.token
        },
        body: JSON.stringify({list: {list_entries_attributes: {"0": {id: this.props.id, group: this.state.text}}}})
      })
        .then(response => {
          if (response.ok) {
            this.myModal.close();
            Alert.show("✅ Group updated");
          } else {
            Alert.show("❌ Error saving group");
            throw {name: "ListGroupError", response: response};
          }
        });
    }
}

export default ListGroup
