import React from "react"

function SearchField(props) {

    return (
        <form noValidate action="/search" method="get" role="search" className={props.class + " input-search"}>
          <input
            name="q"
            type="search"
            autoComplete="off"
            id="q"
            className="input-text"
            onChange={event => {props.onSearch(event.target.value)}}
            onFocus={event => {props.onSearch(event.target.value)}}
            placeholder="Search for a plant"
            tabIndex="0"
            defaultValue={props.store.get("q")}
          />
          <button className="btn-primary-bright" type="submit" title="Search">
            <svg className="search-icon" alt="search" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M16.375 14.5h-.988l-.35-.338A8.089 8.089 0 0017 8.875 8.125 8.125 0 108.875 17a8.089 8.089 0 005.287-1.962l.338.35v.987l6.25 6.237 1.863-1.862zm-7.5 0c-3.112 0-5.625-2.513-5.625-5.625S5.763 3.25 8.875 3.25 14.5 5.763 14.5 8.875 11.987 14.5 8.875 14.5z" /></svg>
          </button>
        </form>
    );
}

export default SearchField
