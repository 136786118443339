import React from "react"
import PropTypes from "prop-types"

class Feedback extends React.Component {
    constructor(props) {
        super(props);
        this.state = { open: false }
        //this we need so we can use this.state in handleClick
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    //the next three functions only exist to listen to clicks
    //outside of the results, so we can hide the results
    //if a user wants to hide the results and clicks somewhere else
    componentDidMount() {
        document.addEventListener('click', this.handleClick);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick);
    }

    handleClick(event) {
        if (!event.target.closest('#feedback')) {
            this.setState({ open: false })
        }
    }

    handleChange(event) {
        event.target.name == 'email'
            ? this.setState({ email: event.target.value })
            : this.setState({ message: event.target.value });
    }

    render() {
        var ui
        if (this.state.open) {

            ui = <>
                <form noValidate onSubmit={evt => this.sendFeedback(evt)} className="feedback-form-container">
                    <h3>Send us feedback</h3>
                    <p>
                        We're building this platform for you — the community. Let us know how we can make your growing journey easier or better.
                    </p>
                    <div className="input-group">
                        <input
                            type="email"
                            className="input-text"
                            name="email"
                            placeholder="Email (optional)"
                            value={this.state.email}
                            onChange={this.handleChange}

                        />
                    </div>
                    <div className="input-group">
                        <textarea
                            className="input-text"
                            name="feedback"
                            placeholder="Don't forget to leave your email if you want us to get back to you!"
                            value={this.state.message}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="pad-t text-right">
                        <a onClick={evt => this.closeForm(evt)} className="btn-clear-light mar-r">Cancel</a>
                        <input type="submit" className="btn-primary" value="Send" />
                    </div>
                </form>
            </>
        }
        var text
        if (this.state.sent) {
            text = <p>Thank you for your feedback!</p>
        } else {
            if (this.props.basic) {
                text = <a className={this.props.class} onClick={evt => this.openForm(evt)}>Send feedback</a>
            } else {
                let className = this.state.open ? '-is-active' : '';
                text = <span className={className + " feedback-btn -hide-bp-sm"} title="Send feedback" onClick={evt => this.openForm(evt)}><svg className="icon icon-gray" aria-label="Send feedback"><use xlinkHref="/icons/__remixicon.sprite.svg#chat-3-line"></use></svg> Send feedback</span>
            }

        }
        return (
            <span id="feedback">
                {text}
                {ui}
            </span>
        );
    }

    openForm(evt) {
        evt.preventDefault()
        this.setState({ open: true })
    }
    closeForm(evt) {
        evt.preventDefault()
        this.setState({ open: false })
    }

    sendFeedback(evt) {
        evt.preventDefault()

        if (this.state.message) {
            fetch("/feedback", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': this.props.token
                },
                body: JSON.stringify({
                    email: evt.target.querySelector('input[name="email"]').value,
                    msg: evt.target.querySelector('textarea[name="feedback"]').value,
                })
            })
                .then(response => {
                    if (response.ok) {
                        this.setState({ open: false, sent: true })
                    }
                })
        }
    }
}

export default Feedback
