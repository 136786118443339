'use strict';

import React from 'react';
import SearchFilterView from "./SearchFilterView";
import {Container} from 'flux/utils';
import SearchStore from './SearchStore';
import Actions from './Actions';

class SearchFilter extends React.Component {
    static getStores() {
        return [SearchStore];
    }

    static calculateState(prevState, props) {
        return {
            ...props,
            store: SearchStore.getState(),
            onFilterShow: Actions.filterShow,
            onFilterHide: Actions.filterHide,
            onFilterSelect: Actions.filterSelect,
            onSelectedFilterDelete: Actions.selectedFilterDelete,
            onFilterReset: Actions.filterReset,
            onFilterSearch: Actions.filterSearch
        };
    }

    componentDidMount() {
        Actions.init(this.props);
    }

    render() {
        return <SearchFilterView {...this.state} />
    }
}

export default Container.create(SearchFilter, {withProps: true})
