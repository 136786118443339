import React from "react"
import PropTypes from "prop-types"
import Loader from "./Loader"

class Comments extends React.Component {
    constructor(props) {
        super(props);
        // move this to this.state because
        // we want to add to it if form is submitted
        this.state = {
            comments: this.props.comments,
            text: ""
        }
    }

    render () {
        return (
            <React.Fragment>
              {this.props.token &&
              <form onSubmit={event => {this.add(event)}} className="comment-box">
                <textarea name="text" value={this.state.text} onChange={event => {this.setState({text: event.target.value})}} className="input-text"/>
                <div className="text-right mar-v">
                    <input type="submit" disabled={!this.state.text} value="Add comment" className="btn-primary" id="addComment" />
                </div>
              </form>
              }
              <List comments={this.state.comments} />
            </React.Fragment>
        );
    }

    add (event) {
        event.preventDefault();

        Loader.set(true, "#addComment");

        fetch(this.props.id + "/comments", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': this.props.token
            },
            body: JSON.stringify({text: event.target.childNodes[0].value})
        })
            .then(response => response.json())
            .then(comment => {
                this.state.comments.unshift(comment)
                this.state.text = ""
                this.setState(this.state)
                Loader.set(false, "#addComment");
            })
    }
}


class List extends React.Component {
    render () {
        return <section className="comments-list">
     {this.props.comments.map((comment, index) => {return <Comment key={index} comment={comment} />})}
    </section>
    }
}

class Comment extends React.Component {

    render () {

        return (
            <article className="comment-container">
                <label className="comment-name">
                  <a href={"/" + this.props.comment.user.name} >{this.props.comment.user.name}</a>
                </label>
                &nbsp;&nbsp;
                <small className="comment-meta">
                    <time dateTime="this.props.comment.created_at">
                    {new Intl.DateTimeFormat("en-GB", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit"
                        }).format( Date.parse(this.props.comment.created_at))}
                    </time>
                </small>
                <p className="comment-text">{this.props.comment.text}</p>
            </article>
        );
    }
}

export default Comments
