import { MeiliSearch } from 'meilisearch'
import React from "react"
import PropTypes from "prop-types"

const client = new MeiliSearch({
  host: location.protocol + '//' + location.host,
})

const indexKeys = client.index('Plant_autosuggest_keys_' + process.env.RAILS_ENV);
const indexValues = client.index('Plant_autosuggest_values_' + process.env.RAILS_ENV);

class DataAttribute extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: this.props.name,
      value: this.props.value,
      hitsKey: [],
      hitsValue: []
    }

    this.handleClick = this.handleClick.bind(this);
  }

  //the next three functions only exist to listen to clicks
  //outside of the results, so we can hide the results
  //if a user wants to hide the results and clicks somewhere else
  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick(event) {
    if (!event.target.closest('#result') && !event.target.closest('#attribute_key')) {
      this.setState({ hitsKey: [] })
    }

    if (!event.target.closest('#result') && !event.target.closest('#attribute_value')) {
      this.setState({ hitsValue: [] })
    }
  }

  render() {
    return <>
      <div className="col-30 dropdown-wrapper">
        <input
          id="attribute_key"
          onFocus={evt => this.searchKey(evt)}
          onChange={evt => this.searchKey(evt)}
          // in react all elements need a stable but unique key
          key="attribute_key"
          name="plant[data][][key]"
          value={this.state.name}
          type="text"
          className="input-text mar-b"
          placeholder="Data label / key"
          required
        />
        {this.state.hitsKey && this.state.hitsKey.length > 0 ? <div id="result" className="dropdown text-left">{this.state.hitsKey.map(hit => { return <a key={hit.id} onClick={evt => this.updateKey(evt, hit)} href=""><div>{hit.key}</div></a> })}</div> : null}
      </div>

      <div className="col-60 dropdown-wrapper">
        <input
          id="attribute_value"
          onFocus={evt => this.searchValue(evt)}
          onChange={evt => this.searchValue(evt)}
          name="plant[data][][value]"
          // in react all elements need a stable but unique key
          key="attribute_value"
          value={this.state.value}
          type="text"
          className="input-text"
          placeholder="Data / value"
        />
        {this.state.hitsValue && this.state.hitsValue.length > 0 ? <div id="result2" className="dropdown text-left">{this.state.hitsValue.map(hit => { return <a key={hit.id} onClick={evt => this.updateValue(evt, hit)} href=""><div>{hit.value}</div></a> })}</div> : null}
      </div>
    </>
  }

  searchKey(evt) {
    // we need this line so the input keeps
    // updating with the current things we type
    this.setState({ name: evt.target.value })

    indexKeys.search(evt.target.value, {
      hitsPerPage: 10
    }).then(({ hits }) => {
      this.setState({ hitsKey: hits });
    });
  }

  //this happens when a user clicks on a autosuggest entry
  updateKey(evt, hit) {
    evt.preventDefault()
    // set the name of the current attr to the value of the first input
    this.setState({ name: hit.key, hitsKey: [] })
  }

  searchValue(evt) {
    // we need this line so the input keeps
    // updating with the current things we type
    this.setState({ value: evt.target.value })

    if (this.state.name) {
      indexValues.search(evt.target.value, {
        hitsPerPage: 10,
        filter: "key='" + this.state.name + "'"
      }).then(({ hits }) => {
        this.setState({ hitsValue: hits });
      });
    }
  }

  updateValue(evt, hit) {
    evt.preventDefault()
    // set the name of the current attr to the value of the first input
    this.setState({ value: hit.value, hitsValue: [] })
  }
}

class PlantFormDataAttributes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: this.props.attributes.map((attr, index) => {
        return <DataAttribute key={index} name={attr.key} value={attr.value} />
      })
    }
    //after we populated the initial attributes array...
    //this is just the internal counter we start here to increase keys only when adding new attributes
    this.state.counter = this.state.attributes.length
  }

  render() {
    return <>
      {this.state.attributes.map(attr => {
        return <div key={attr.key} className="row row-gutters responsive-sm mar-b">
          {attr}
          <div className="col-10 text-right">
            <a key={"a" + attr.key} id={attr.key} className="btn-icon-clear-danger mar-r" onClick={evt => this.delAttr(evt)}>
              <svg id={attr.key} className="icon icon-gray"><use xlinkHref="/icons/__remixicon.sprite.svg#delete-bin-line" /></svg>
            </a>
          </div>
        </div>
      })}

      <a href="#" className="btn-secondary-dark btn-u-block" onClick={evt => this.addAttr(evt)}>Add attribute field</a>

    </>
  }

  addAttr(evt) {
    evt.preventDefault()
    //add a new empty attr to the array
    this.state.attributes.push(<DataAttribute key={this.state.counter} name="" value="" />)
    this.state.counter++
    this.setState(this.state)
  }

  delAttr(evt) {
    if (!evt.target.id) {
      throw new Error("the id of evt.target cannot be found.");
      return;
    }

    // find the attr with key the same as the id of the a element clicked
    var attr = this.state.attributes.filter(attr => attr.key === evt.target.id)[0]
    // find the current index of this
    var index = this.state.attributes.indexOf(attr)
    // remove it from the array with the index
    this.state.attributes.splice(index, 1)
    this.setState(this.state)
  }
}

PlantFormDataAttributes.propTypes = {
  attributes: PropTypes.array
};

export default PlantFormDataAttributes
