import { MeiliSearch } from 'meilisearch'
import React from "react"

const client = new MeiliSearch({
  host: location.protocol + '//' + location.host,
})

const plantsIndex = client.index('Plant_' + process.env.RAILS_ENV);

class PlantName extends React.Component {
    constructor(props) {
        super(props);
        this.state = {name: props.name || '', hits: []}
    }

    render () {
        var duplicates
        if (this.state.hits.length > 0) {
            duplicates = <p>Possible duplicates: {this.state.hits.map(hit => {return <a key={hit.id} href={hit.link}>{hit.scientific_name} ({hit.name})</a> }).reduce((prev, curr) => [prev, ', ', curr])}</p>
        }
        return <>
                 <input
                  id={"plant_" + this.props.field }
                  name={"plant["+this.props.field+"]"}
                  className="input-text"
                  onChange={event => {this.perform(event.target.value)}}
                  placeholder={this.props.placeholder}
                  value={this.state.name}
                />
                {duplicates}
            </>
    }

    perform(q) {
        var filters = [];

        if (this.props.id) {
            filters.push("id != " + this.props.id)
        }

        if (this.props.parent_id) {
            filters.push("parent_id = " + this.props.parent_id)
        }

        this.setState({name: q})
        if (q !== "") {
            index.search(q, {
                filters: filters.join(" AND "),
                hitsPerPage: 10,
            }).then((hits) => {
                this.setState({hits: hits.hits});
            });
        } else {
            this.setState({hits: []});
        }
    }
}

export default PlantName
